var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-end mt-2" },
    [
      _c("basic-loader", { staticClass: "rounded-full w-1/3 h-6" }),
      _c(
        "div",
        { staticClass: "flex w-2/3" },
        [
          _c(
            "div",
            { staticClass: "px-4 w-3/4" },
            [_c("basic-loader", { staticClass: "rounded-full w-full h-4" })],
            1
          ),
          _c("basic-loader", { staticClass: "rounded-full w-1/4 h-4" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }