var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-6 pb-4 flex flex-col w-full border-r-2" },
    [
      _c(
        "div",
        { staticClass: "text-xs uppercase font-bold text-gray-500 text-left" },
        [_vm._v(_vm._s(_vm.title))]
      ),
      !_vm.isLoading
        ? _c(
            "div",
            {
              staticClass:
                "flex items-baseline flex-wrap text-gray-800 whitespace-no-wrap",
            },
            [
              _c(
                "span",
                { staticClass: "xl:w-full text-2xl text-left mr-2" },
                [_vm._t("value")],
                2
              ),
              _vm.showSlot
                ? _c("div", { staticClass: "flex flex-1" }, [
                    _c(
                      "span",
                      { staticClass: "text-gray-700 h-3" },
                      [_vm._t("diffValue")],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-1 text-right" },
                      [
                        _c("sun-pill", { attrs: { color: _vm.color } }, [
                          _c(
                            "div",
                            { staticClass: "flex text-xs" },
                            [
                              _vm.diff > 0
                                ? _c("arrow-down-svg", {
                                    staticClass:
                                      "rotate-180 text-green-500 w-4",
                                  })
                                : _vm._e(),
                              _vm.diff < 0
                                ? _c("arrow-down-svg", {
                                    staticClass: "text-red-500 w-4",
                                  })
                                : _vm._e(),
                              _vm._t("percentage"),
                            ],
                            2
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _c("stat-box-loader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }