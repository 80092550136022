<template>
  <transition name="fade">
    <sun-alert v-if="notifications.length" variant="warn" :dismissible="false">
      <span slot="title" class="flex"><warning-svg class="w-6 mr-2" />Insertion Notification</span>
      <div slot="description">
        <p v-for="notify in notifications" :key="'jon_noti_' + notify.id" class="mt-1" v-text="notify.message"></p>
      </div>
    </sun-alert>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';
import ApiRequest from '@/utils/apiRequest';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';

export default {
  name: 'IngestionJobNotifications',
  components: {
    WarningSvg: () => import('@/components/icons/WarningSvg.vue'),
  },
  props: {
    service: {
      type: Function,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    notifications: [],
  }),
  created() {
    this.getIngestionJobNotifications();
  },
  methods: {
    ...mapActions([CREATE_TOAST]),

    getIngestionJobNotifications() {
      ApiRequest(async () => {
        const { data } = await this.service(this.clientId);
        this.notifications = data;
      }).catch(() => {
        this[CREATE_TOAST](Toast.error('Analytics', 'We can not check ingestion jobs.'));
      });
    },
  },
};
</script>
