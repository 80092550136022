var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "fade" } },
    [
      _vm.notifications.length
        ? _c("sun-alert", { attrs: { variant: "warn", dismissible: false } }, [
            _c(
              "span",
              { staticClass: "flex", attrs: { slot: "title" }, slot: "title" },
              [
                _c("warning-svg", { staticClass: "w-6 mr-2" }),
                _vm._v("Insertion Notification"),
              ],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "description" }, slot: "description" },
              _vm._l(_vm.notifications, function (notify) {
                return _c("p", {
                  key: "jon_noti_" + notify.id,
                  staticClass: "mt-1",
                  domProps: { textContent: _vm._s(notify.message) },
                })
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }