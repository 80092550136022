<template>
  <div class="p-6 pb-4 flex flex-col w-full border-r-2">
    <div class="text-xs uppercase font-bold text-gray-500 text-left">{{ title }}</div>
    <div v-if="!isLoading" class="flex items-baseline flex-wrap text-gray-800 whitespace-no-wrap">
      <span class="xl:w-full text-2xl text-left mr-2">
        <slot name="value"></slot>
      </span>
      <div v-if="showSlot" class="flex flex-1">
        <span class="text-gray-700 h-3">
          <slot name="diffValue"></slot>
        </span>
        <div class="flex-1 text-right">
          <sun-pill :color="color">
            <div class="flex text-xs">
              <arrow-down-svg v-if="diff > 0" class="rotate-180 text-green-500 w-4" />
              <arrow-down-svg v-if="diff < 0" class="text-red-500 w-4" />
              <slot name="percentage"></slot>
            </div>
          </sun-pill>
        </div>
      </div>
    </div>
    <stat-box-loader v-else />
  </div>
</template>

<script>
import ArrowDownSvg from '@/components/icons/ArrowDownSvg.vue';
import SunPill from '@/components/atoms/SunPill/SunPill.vue';
import StatBoxLoader from '@/components/atoms/Loaders/StatBoxLoader.vue';

export default {
  name: 'StatsBox',
  components: {
    ArrowDownSvg,
    SunPill,
    StatBoxLoader,
  },
  props: {
    title: {
      type: String,
      default: () => null,
    },
    diff: {
      type: [String, Number],
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    color() {
      if (this.diff === 0) return 'yellow';
      if (this.diff < 0) return 'red';
      return 'green';
    },
    showSlot() {
      return this.$slots.percentage || this.$slots.diffValue;
    },
  },
};
</script>
